
import { Suspense, lazy } from 'react';
import { Routes, Route } from "react-router-dom";

import { useSelector } from 'react-redux';
import { getUserAccess } from 'store/auth/auth-selectors';
import { nanoid } from 'nanoid';
import { Web3Modal } from '@web3modal/react';
import { config } from './components/widgets/connectWalletBtn/config';
import axios from 'axios';
import colors from "./sass/variables.scss"

import Preloader from './components/shared/loaders/preloader/Preloader'
import Header from './components/widgets/header/Header';
import Footer from './components/widgets/footer/Footer';
import Copyright from './components/shared/copyright/Copyright';

const MainPage = lazy(() => import('./views/main/MainPage'));
const Vouchers = lazy(() => import('./views/vouchers/VouchersPage'));
const ErrorPage = lazy(() => import('./views/404Page/404Page'));
const SoonPage = lazy(() => import('./views/soon/Soon'));


function App() {
  const token = useSelector(getUserAccess);
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common = { Authorization: `bearer ${token}` };

  // const [isReadyToRender, setIsReadyToRender] = useState<boolean>(false);
  // window.onload = () => setIsReadyToRender(true);
  return (
    <>
      <Header />
      < Suspense
        fallback={
          <Preloader />
        }
      >
        <Routes>
          <Route path="/" element={[<MainPage key={nanoid()} />, <Footer key={nanoid()} />, <Copyright key={nanoid()} />]}></Route>
          <Route path="/vouchers" element={[<Vouchers key={nanoid()} />, <Footer key={nanoid()} background={colors.sectionBackground} />, <Copyright key={nanoid()} background={colors.secondBlackBackground} />]}></Route>
          <Route path="*" element={[<ErrorPage key={nanoid()} />, <Copyright key={nanoid()} background={colors.blackBackground} />]}>
          </Route>
          <Route path="/soon" element={[<SoonPage key={nanoid()} />, <Copyright key={nanoid()} background={colors.blackBackground} />
          ]}>
          </Route>
        </Routes >
      </Suspense >
      <Web3Modal config={config} />
    </>
  );
}

export default App;
