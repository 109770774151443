import { createSlice } from '@reduxjs/toolkit';
import { getProductsOperation } from './products-operation';
// import { IconSuccess, TextSuccess } from 'components/shared/toasts/Toasts';
// import { toast, ToastContainer } from 'react-toastify';

const initialState = {
    products: [{
        _id: "",
        name: "",
        desc: "",
        img: "",
        url: ""
    }],
};

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},

    extraReducers: builder => {
        builder.addCase(getProductsOperation.fulfilled, (state, action) => {

            state.products = action.payload;
            // state.isLoggedIn = true;
            // toast.success(<TextSuccess />, { icon: IconSuccess });
            // <ToastContainer/>
        });
    },
});
export default productsSlice.reducer;
