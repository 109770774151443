import s from "./SocialIcons.module.scss"

import React from 'react';

import sprite from '../../../assets/svg/sprite.svg';


interface SocialIconsProps {
  style?: any;
}

const SocialIcons: React.FC<SocialIconsProps> = () => {
  return (
    <ul className={s.list}>

      <li className={s.item}>
        <a className={s.link} href="https://discord.com/invite/cMnBSwm2">
          <svg className={s.svg}>
            <use href={sprite + '#icon-discord'} />
          </svg>
          <span className={s.text}>Discord</span>
        </a>
      </li>

      <li className={s.item}>
        <a className={s.link} href="https://twitter.com/WEB3FORCES">
          <svg className={s.svg}>
            <use href={sprite + '#icon-twitter'} />
          </svg>
          <span className={s.text}>Twitter</span>
        </a>
      </li>

      <li className={s.item}>
        <a className={s.link} href="https://t.me/web3forces">
          <svg className={s.svg}>
            <use href={sprite + '#icon-telegram'} />
          </svg>
          <span className={s.text}>News</span>
        </a>
      </li>

      <li className={s.item}>
        <a className={s.link} href="https://t.me/forcesdao">
          <svg className={s.svg}>
            <use href={sprite + '#icon-telegram'} />
          </svg>
          <span className={s.text}>DAO</span>
        </a>
      </li>

      <li className={s.item}>
        <a className={s.link} href="https://www.youtube.com/c/WEB3FORCES">
          <svg className={s.svg}>
            <use href={sprite + '#icon-youtube'} />
          </svg>
          <span className={s.text}>YouTube</span>
        </a>
      </li>

      <li className={s.item}>
        <a className={s.link} href="https://www.twitch.tv/web3forces">
          <svg className={s.svg}>
            <use href={sprite + '#icon-twitch'} />
          </svg>
          <span className={s.text}>Twitch</span>
        </a>
      </li>

      <li className={s.item}>
        <a className={s.link} href="https://www.facebook.com/web3forces">
          <svg className={s.svg}>
            <use href={sprite + '#icon-facebook'} />
          </svg>
          <span className={s.text}>Facebook</span>
        </a>
      </li>

      <li className={s.item}>
        <a className={s.link} href="https://medium.com/@web3forces">
          <svg className={s.svg}>
            <use href={sprite + '#icon-medium'} />
          </svg>
          <span className={s.text}>Medium</span>
        </a>
      </li>

      <li className={s.item}>
        <a className={s.link} href="https://github.com/orgs/web3forces">
          <svg className={s.svg}>
            <use href={sprite + '#icon-github'} />
          </svg>
          <span className={s.text}>Github</span>
        </a>
      </li>

    </ul>
  );
};

export default SocialIcons;
