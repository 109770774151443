
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json'
import translationUA from './locales/ua/translation.json'
import translationRU from './locales/ru/translation.json'

const resources = {
  en: {
    translation: translationEN
  },
  ua: {
    translation: translationUA
  },
  ru: {
    translation: translationRU
  }
};
i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    debug: false,
    supportedLngs: ['en', 'ua','ru'],
    resources,
    nonExplicitSupportedLngs: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    // debug: process.env.NODE_ENV === 'development',
  });

export default i18next;
